<template>
	<div class="healthyText">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="姓名：">
					<el-autocomplete v-model.trim="formInline.name" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item label="性别：">
					<el-select v-model="formInline.genderDictItemId" placeholder="请选择">
						<el-option v-for="(item, index) in sexDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="省：" v-if="!checkTab">
					<el-select v-model="formInline.provinceDictionaryItem" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：" v-if="!checkTab">
					<el-select v-model="formInline.cityDictionaryItem" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：" v-if="!checkTab">
					<el-select v-model="formInline.region" placeholder="请选择区县">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="tabClass">
				<span :class="{ activeClass: checkTab === true }" @click="changeTab(true)">本区域</span>
				<span :class="{ activeClass: checkTab === false }" @click="changeTab(false)">本区域外</span>
			</div>
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="false"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!--基本信息  -->
				<template #basicInfo="{ scope }">
					<el-button @click="showBasicInfo(scope.row, 1)" size="mini" type="text"> 查看 </el-button>
				</template>
				<!-- 健康体检表 -->
				<template #examinationForm="{ scope }">
					<el-button @click="showBasicInfo(scope.row, 2)" size="mini" type="text"> 查看 </el-button>
				</template>
				<!-- 重点人群健康管理 -->
				<template #importantPeople="{ scope }">
					<el-button @click="showBasicInfo(scope.row, 3)" size="mini" type="text"> 查看 </el-button>
				</template>
				<!-- 其他医疗服务记录 -->
				<template #otherMedicalServices="{ scope }">
					<el-button @click="showBasicInfo(scope.row, 4)" size="mini" type="text"> 查看 </el-button>
				</template>
				<!-- 档案信息卡 -->
				<template #fileInformationCard="{ scope }">
					<el-button @click="showBasicInfo(scope.row, 5)" size="mini" type="text"> 查看 </el-button>
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'healthyText',
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				name: '',
				genderDictItemId: null,
				provinceDictionaryItem: null,
				cityDictionaryItem: null,
				region: null,
			},
			sexDictionaryItemList: [],
			economizeList: [],
			marketList: [],
			districtList: [],
			queryData: {
				current: 1,
				size: 10,
			},
			checkTab: true,
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '姓名',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '性别',
					prop: 'genderDictionaryItem',
					formatter: (row) => {
						return row.genderDictionaryItem?.title || '-';
					},
				},
				{
					label: '基本信息',
					prop: 'basicInfo',
				},
				{
					label: '健康体检表',
					prop: 'examinationForm',
				},
				{
					label: '重点人群健康管理',
					prop: 'importantPeople',
				},
				{
					label: '其他医疗服务记录',
					prop: 'otherMedicalServices',
				},
				{
					label: '档案信息卡',
					prop: 'fileInformationCard',
				},
				{
					label: '所属区域',
					prop: 'regions',
					formatter: (row) => {
						return (row.regions && row.regions.map((v) => v.title).join('')) || '-';
					},
				},
				{
					label: '建档人',
					prop: 'creatorName',
					formatter: (row) => {
						return row.creatorName || '-';
					},
				},
				{
					label: '建档时间',
					prop: 'createTime',
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
			],
			isShowDetail: false,
			options: [],
			filterLoading: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 354;
		},
	},
	mounted() {
		this.getSexList();
		this.getList();
		this.getEconomizeList();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			switch (type) {
				case 1:
					this.formInline.cityDictionaryItem = null;
					this.formInline.region = null;
					this.marketList = [];
					this.districtList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.region = null;
					this.districtList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		// 获取性别下拉
		getSexList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'GENDER' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.sexDictionaryItemList = res.data.collection || [];
						this.sexDictionaryItemList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 是否启用
		changeOpen(row) {
			this.$http
				.patch(row._links.patch.href, { enabled: row.enabled })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		changeTab(val) {
			this.checkTab = val;
			this.formInline.name = '';
			this.queryData.current = 1;
			this.getList();
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			const { name, genderDictItemId, provinceDictionaryItem, cityDictionaryItem, region } = this.formInline;
			let data = {
				name,
				genderDictItemId,
				...this.queryData,
				inArea: this.checkTab,
				regionCode:
					!this.checkTab && provinceDictionaryItem && !cityDictionaryItem && !region
						? provinceDictionaryItem
						: !this.checkTab && provinceDictionaryItem && cityDictionaryItem && !region
						? cityDictionaryItem
						: !this.checkTab && provinceDictionaryItem && cityDictionaryItem && region
						? region
						: null,
			};
			this.$http
				.get(this.api['People#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.$refs.createView.link = res.data._links.create.href || '';
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		// 跳转详情页
		showBasicInfo(row, type) {
			this.$router.push({
				path: '/publicHealth/healthyText/showDetails',
				query: {
					link: row._links.load.href,
					type: type,
					id: row.id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	.tabClass {
		width: calc(100% + 32px);
		line-height: 48px;
		border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
		margin-left: -16px;
		padding: 0 16px;
		span {
			display: inline-block;
			line-height: 48px;
			margin-right: 32px;
			font-size: 16px;
			cursor: pointer;
			&:hover {
				color: #1db9b1;
				border-bottom: 2px solid #1db9b1;
			}
		}
		.activeClass {
			color: #1db9b1;
			border-bottom: 2px solid #1db9b1;
		}
	}
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>